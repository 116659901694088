/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { VorerfassteBetankung } from './VorerfassteBetankung';
import {
    VorerfassteBetankungFromJSON,
    VorerfassteBetankungFromJSONTyped,
    VorerfassteBetankungToJSON,
} from './VorerfassteBetankung';

/**
 * 
 * @export
 * @interface VorerfassteBetankungenResponse
 */
export interface VorerfassteBetankungenResponse {
    /**
     * Liste von vorerfassten Tankungvorgängen.
     * @type {Array<VorerfassteBetankung>}
     * @memberof VorerfassteBetankungenResponse
     */
    vorerfassteBetankungen: Array<VorerfassteBetankung>;
}

/**
 * Check if a given object implements the VorerfassteBetankungenResponse interface.
 */
export function instanceOfVorerfassteBetankungenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vorerfassteBetankungen" in value;

    return isInstance;
}

export function VorerfassteBetankungenResponseFromJSON(json: any): VorerfassteBetankungenResponse {
    return VorerfassteBetankungenResponseFromJSONTyped(json, false);
}

export function VorerfassteBetankungenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VorerfassteBetankungenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vorerfassteBetankungen': ((json['vorerfassteBetankungen'] as Array<any>).map(VorerfassteBetankungFromJSON)),
    };
}

export function VorerfassteBetankungenResponseToJSON(value?: VorerfassteBetankungenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vorerfassteBetankungen': ((value.vorerfassteBetankungen as Array<any>).map(VorerfassteBetankungToJSON)),
    };
}

