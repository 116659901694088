import { getStringEnumValues } from "@rsrg-bbw/libraries-common/dist/objectUtils";

enum Environment {
  DEVELOPMENT = "DEV",
  TEST = "TST",
  STAGING = "STG",
  PRODUCTION = "PRD",
}

const POSSIBLE_ENVIRONMENTS = new Set(getStringEnumValues(Environment));

const environment: Environment = (() => {
  const forcedEnvironment = getForcedEnvironment();
  if (forcedEnvironment) {
    return forcedEnvironment;
  } else {
    return detectEnvironment();
  }
})();

function detectEnvironment() {
  if (window.location.hostname.includes(".prd.")) {
    return Environment.PRODUCTION;
  } else if (window.location.hostname.includes(".tst.")) {
    return Environment.TEST;
  } else if (window.location.hostname.includes(".stg.")) {
    return Environment.STAGING;
  } else {
    return Environment.DEVELOPMENT;
  }
}

export { Environment, POSSIBLE_ENVIRONMENTS, environment };

function getForcedEnvironment(): Environment | undefined {
  if (
    __FORCED_ENV__ &&
    POSSIBLE_ENVIRONMENTS.has(__FORCED_ENV__) &&
    __FORCED_ENV__ != Environment.PRODUCTION
  ) {
    return __FORCED_ENV__;
  } else {
    return undefined;
  }
}
