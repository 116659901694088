/* tslint:disable */
/* eslint-disable */
/**
 * Bericht Einreichen Connector
 * RVC Berichte in das Berichtsmanagement einreichen.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  UserInfo,
} from '../models/index';
import {
    UserInfoFromJSON,
    UserInfoToJSON,
} from '../models/index';

export interface GetUserInfoRequest {
    id: string;
}

export interface GetUserPhotoRequest {
    id: string;
}

/**
 * UserApi - interface
 * 
 * @export
 * @interface UserApiInterface
 */
export interface UserApiInterface {
    /**
     * 
     * @param {string} id Id or userPrincipalName.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserInfoRaw(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>>;

    /**
     */
    getUserInfo(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo>;

    /**
     * 
     * @param {string} id Id or userPrincipalName.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApiInterface
     */
    getUserPhotoRaw(requestParameters: GetUserPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     */
    getUserPhoto(requestParameters: GetUserPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI implements UserApiInterface {

    /**
     */
    async getUserInfoRaw(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserInfo>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserInfo.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/v1.0/users/{id}?$select=id,userPrincipalName,jobTitle,mail,mobilePhone,preferredLanguage,displayName,companyName,department`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoFromJSON(jsonValue));
    }

    /**
     */
    async getUserInfo(requestParameters: GetUserInfoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserInfo> {
        const response = await this.getUserInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserPhotoRaw(requestParameters: GetUserPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserPhoto.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/v1.0/users/{id}/photos/120x120/$value`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async getUserPhoto(requestParameters: GetUserPhotoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getUserPhotoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
