/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Betankung } from './Betankung';
import {
    BetankungFromJSON,
    BetankungFromJSONTyped,
    BetankungToJSON,
} from './Betankung';
import type { VorerfassteBetankung } from './VorerfassteBetankung';
import {
    VorerfassteBetankungFromJSON,
    VorerfassteBetankungFromJSONTyped,
    VorerfassteBetankungToJSON,
} from './VorerfassteBetankung';

/**
 * 
 * @export
 * @interface CsvCheckResponse
 */
export interface CsvCheckResponse {
    /**
     * Vorerfasste Tankvorgänge des in der CSV übermittelten Monats.
     * @type {Array<VorerfassteBetankung>}
     * @memberof CsvCheckResponse
     */
    vorerfassteBetankungen: Array<VorerfassteBetankung>;
    /**
     * Tankvorgänge die auf Sammelkarten gebucht wurden (inkl. WA-Nummer, falls ein Werkstattauftrag über eine Vorerfassung zugeordnet werden konnte).
     * @type {Array<Betankung>}
     * @memberof CsvCheckResponse
     */
    sammelkartenBetankungen: Array<Betankung>;
    /**
     * Tankvorgänge die nicht auf Sammelkarten gebucht wurden (inkl. WA-Nummer, falls ein Werkstattauftrag über die Fahrzeugnummer zugeordnet werden konnte).
     * @type {Array<Betankung>}
     * @memberof CsvCheckResponse
     */
    betankungen: Array<Betankung>;
    /**
     * Gibt den Monatsersten des Monats der Importdatei zurück.
     * @type {string}
     * @memberof CsvCheckResponse
     */
    monat: string;
    /**
     * Signatur für nächsten Prozessschritt
     * @type {string}
     * @memberof CsvCheckResponse
     */
    signatur: string;
}

/**
 * Check if a given object implements the CsvCheckResponse interface.
 */
export function instanceOfCsvCheckResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "vorerfassteBetankungen" in value;
    isInstance = isInstance && "sammelkartenBetankungen" in value;
    isInstance = isInstance && "betankungen" in value;
    isInstance = isInstance && "monat" in value;
    isInstance = isInstance && "signatur" in value;

    return isInstance;
}

export function CsvCheckResponseFromJSON(json: any): CsvCheckResponse {
    return CsvCheckResponseFromJSONTyped(json, false);
}

export function CsvCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CsvCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vorerfassteBetankungen': ((json['vorerfassteBetankungen'] as Array<any>).map(VorerfassteBetankungFromJSON)),
        'sammelkartenBetankungen': ((json['sammelkartenBetankungen'] as Array<any>).map(BetankungFromJSON)),
        'betankungen': ((json['betankungen'] as Array<any>).map(BetankungFromJSON)),
        'monat': json['monat'],
        'signatur': json['signatur'],
    };
}

export function CsvCheckResponseToJSON(value?: CsvCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vorerfassteBetankungen': ((value.vorerfassteBetankungen as Array<any>).map(VorerfassteBetankungToJSON)),
        'sammelkartenBetankungen': ((value.sammelkartenBetankungen as Array<any>).map(BetankungToJSON)),
        'betankungen': ((value.betankungen as Array<any>).map(BetankungToJSON)),
        'monat': value.monat,
        'signatur': value.signatur,
    };
}

