/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Betankung
 */
export interface Betankung {
    /**
     * 
     * @type {number}
     * @memberof Betankung
     */
    fahrzeugNummer: number;
    /**
     * 
     * @type {string}
     * @memberof Betankung
     */
    zeitpunkt: string;
    /**
     * 
     * @type {number}
     * @memberof Betankung
     */
    menge: number;
    /**
     * 
     * @type {string}
     * @memberof Betankung
     */
    werkstattAuftragNummer?: string;
}

/**
 * Check if a given object implements the Betankung interface.
 */
export function instanceOfBetankung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fahrzeugNummer" in value;
    isInstance = isInstance && "zeitpunkt" in value;
    isInstance = isInstance && "menge" in value;

    return isInstance;
}

export function BetankungFromJSON(json: any): Betankung {
    return BetankungFromJSONTyped(json, false);
}

export function BetankungFromJSONTyped(json: any, ignoreDiscriminator: boolean): Betankung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fahrzeugNummer': json['fahrzeugNummer'],
        'zeitpunkt': json['zeitpunkt'],
        'menge': json['menge'],
        'werkstattAuftragNummer': !exists(json, 'werkstattAuftragNummer') ? undefined : json['werkstattAuftragNummer'],
    };
}

export function BetankungToJSON(value?: Betankung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fahrzeugNummer': value.fahrzeugNummer,
        'zeitpunkt': value.zeitpunkt,
        'menge': value.menge,
        'werkstattAuftragNummer': value.werkstattAuftragNummer,
    };
}

