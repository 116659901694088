/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WerkstattAuftragAuswahl
 */
export interface WerkstattAuftragAuswahl {
    /**
     * 
     * @type {string}
     * @memberof WerkstattAuftragAuswahl
     */
    werkstattAuftragNummer: string;
    /**
     * 
     * @type {number}
     * @memberof WerkstattAuftragAuswahl
     */
    fahrzeugNummer: number;
    /**
     * 
     * @type {string}
     * @memberof WerkstattAuftragAuswahl
     */
    kennzeichen: string;
    /**
     * 
     * @type {string}
     * @memberof WerkstattAuftragAuswahl
     */
    gueltigVon: string;
    /**
     * 
     * @type {string}
     * @memberof WerkstattAuftragAuswahl
     */
    gueltigBis: string;
    /**
     * 
     * @type {string}
     * @memberof WerkstattAuftragAuswahl
     */
    beschreibung: string;
}

/**
 * Check if a given object implements the WerkstattAuftragAuswahl interface.
 */
export function instanceOfWerkstattAuftragAuswahl(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "werkstattAuftragNummer" in value;
    isInstance = isInstance && "fahrzeugNummer" in value;
    isInstance = isInstance && "kennzeichen" in value;
    isInstance = isInstance && "gueltigVon" in value;
    isInstance = isInstance && "gueltigBis" in value;
    isInstance = isInstance && "beschreibung" in value;

    return isInstance;
}

export function WerkstattAuftragAuswahlFromJSON(json: any): WerkstattAuftragAuswahl {
    return WerkstattAuftragAuswahlFromJSONTyped(json, false);
}

export function WerkstattAuftragAuswahlFromJSONTyped(json: any, ignoreDiscriminator: boolean): WerkstattAuftragAuswahl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'werkstattAuftragNummer': json['werkstattAuftragNummer'],
        'fahrzeugNummer': json['fahrzeugNummer'],
        'kennzeichen': json['kennzeichen'],
        'gueltigVon': json['gueltigVon'],
        'gueltigBis': json['gueltigBis'],
        'beschreibung': json['beschreibung'],
    };
}

export function WerkstattAuftragAuswahlToJSON(value?: WerkstattAuftragAuswahl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'werkstattAuftragNummer': value.werkstattAuftragNummer,
        'fahrzeugNummer': value.fahrzeugNummer,
        'kennzeichen': value.kennzeichen,
        'gueltigVon': value.gueltigVon,
        'gueltigBis': value.gueltigBis,
        'beschreibung': value.beschreibung,
    };
}

