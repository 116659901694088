<template>
  <div
    :class="{ 'sticky-bottom': isSticky, 'warning-box': showWarning }"
    class="page-footer"
  >
    <span>{{ version }}</span>
    <span class="environment" v-if="showWarning">{{ warning }}</span>
    <!-- Dummy for proper centering -->
    <span v-if="showWarning">{{ version }}</span>
  </div>
</template>

<script setup lang="ts">
  import { ref, onMounted, onUnmounted } from "vue";

  const props = defineProps<{
    showWarning: boolean;
    version: string;
    warning: string | undefined;
  }>();

  const isSticky = ref(false);

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const threshold = document.body.offsetHeight - 30; // Adjust this threshold as needed
    isSticky.value = scrollPosition < threshold;
  };

  onMounted(() => {
    window.addEventListener("scroll", handleScroll);
  });

  onUnmounted(() => {
    window.removeEventListener("scroll", handleScroll);
  });
</script>

<style scoped lang="scss">
  .sticky-bottom {
    position: fixed;
    bottom: 0;
  }

  .page-footer {
    color: #999999;
    font-size: 0.6em;
    margin: 0 0.5em 0.5em 0.5em;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.warning-box span {
      &.environment {
        flex-grow: 1;
      }

      &:not(.environment) {
        flex-shrink: 0;
      }

      &:last-of-type:not(:only-of-type) {
        visibility: hidden;
      }
    }
  }

  .warning-box {
    border: solid 2px rgba(#ffa500, 0.4);
    background-color: rgba(#f5deb3, 0.9);
    padding: 0.3em;

    .environment {
      color: #222;
      font-size: 1.5em;
      text-align: center;
    }
  }
</style>
