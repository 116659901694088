<template>
  <div class="headline-container">
    <div class="menu-container">
      <a class="link-size" href="/"
        ><img class="logo-size" src="/images/logo_rsrg_inverted.svg"
      /></a>
    </div>
    <div class="title-container">
      <div v-if="route.meta.headline" class="item">
        <h1>{{ route.meta.headline }}</h1>
      </div>
    </div>

    <div>
      <Button
        link
        class="button-size"
        v-if="isAuthenticated"
        type="button"
        @click="toggle"
        label="Profil"
        ><img
          v-if="userPhoto"
          class="profile-picture"
          :src="userPhoto"
          alt="Profilbild" />

        <div v-else class="pi pi-user profile-picture-empty"></div
      ></Button>

      <OverlayPanel ref="op">
        <div class="overlay-container">
          <Avatar
            v-if="userPhoto"
            class="avatar-design"
            :image="userPhoto"
            shape="circle"
          ></Avatar>
          <Avatar
            v-else
            class="avatar-design avatar-profile pi pi-user"
          ></Avatar>
          <div class="logout-info">
            <div>{{ name }}</div>
            <Button class="logout-button" @click="logoutRedirect" link
              ><i class="pi pi-sign-out"></i>Abmelden</Button
            >
          </div>
        </div>
      </OverlayPanel>
      <div class="signin-text">
        <SignInButton v-if="!isAuthenticated" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useIsAuthenticated } from "@/composition-api/useIsAuthenticated";
  import SignInButton from "./SignInButton.vue";
  import { Ref, computed, ref, watch } from "vue";
  import { showProductionSystemWarning } from "@/utils/devutils";
  import { useRoute } from "vue-router";
  import { useUserMagic } from "@/composition-api/useUserMagic";
  import UserInfo from "../utils/UserInfo";
  import Button from "primevue/button";
  import { useMsal } from "@/composition-api/useMsal";
  import Avatar from "primevue/avatar";
  import OverlayPanel from "primevue/overlaypanel";
  import handleHttpError from "@rsrg-bbw/libraries-common/dist/handleHttpError";

  const isAuthenticated = useIsAuthenticated();
  const navbar = ref<HTMLElement>();
  const route = useRoute();
  const userDetails = ref<UserInfo | undefined | null>();
  const userMagic = useUserMagic();
  const userPhoto = ref<string | undefined | null>();

  watch(
    [showProductionSystemWarning, navbar],
    (v) => {
      if (showProductionSystemWarning.value && navbar.value) {
        navbar.value.style.backgroundColor = "rgb(255 105 72)";
      }
    },
    { immediate: true },
  );

  watch(
    userMagic.currentUserId,
    (currentUserId) => {
      if (currentUserId) {
        userMagic
          .getUserInfo(currentUserId)
          .then((r) => (userDetails.value = r))
          .catch((e) => {
            handleHttpError(e);
          });
        userMagic
          .getUserPhoto(currentUserId)
          .then((r) => (userPhoto.value = r))
          .catch((e) => {
            handleHttpError(e);
          });
      }
    },
    { immediate: true },
  );

  const { accounts } = useMsal();
  const { instance } = useMsal();

  const name = computed(() => {
    if (accounts.value.length > 0) {
      const name = accounts.value[0].name;

      if (name) {
        return name.split("  ")[0];
      }
    }
    return "";
  });

  const logoutRedirect = () => {
    instance.logoutRedirect();
  };

  const op: Ref<OverlayPanel | undefined> = ref();

  const toggle = (event: MouseEvent) => {
    op.value?.toggle(event);
  };
</script>

<style scoped lang="scss">
  .overlay-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row-reverse;
  }

  .logout-info {
    color: #000;
    font-weight: 700;
    font-size: 1.3em;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
  }

  .logout-button {
    color: var(--rsrg-orange);
    font-family: var(--font-family);
    font-size: 1em;
    font-weight: 600;
    display: flex;
    gap: 0.3em;

    i {
      margin-top: 0.2em;
      font-weight: 600;
      font-size: 1rem;
    }
  }

  .button-size {
    height: 4em;
    width: 4em;
    border-radius: 5px;
    padding: 0;
  }

  .headline-container {
    background-color: var(--rsrg-grey);
    height: 5.5em;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 1em;
    gap: 2em;

    h1 {
      color: var(--white-color);
      font-weight: 700;
      font-size: 1.5em;
    }
  }

  .title-container {
    flex-grow: 1;
  }

  .menu-container {
    display: flex;
    justify-content: start;
  }

  .logo-size {
    height: 100%;
    width: 100%;
    min-width: 138px;
  }

  .link-size {
    height: 4.5em;
  }

  .profile-picture {
    height: 4em;
    width: 4em;
    border-radius: 5px;
  }

  .profile-picture-empty {
    height: 4em;
    width: 4em;
    border-radius: 5px;
    background-color: var(--profile-bg-color);
    border: solid 2px var(--rsrg-orange);
    color: var(--rsrg-orange);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
  }

  .avatar-design {
    margin: 1em;
    height: 4em;
    width: 4em;
    border: #000 solid 2px;
  }

  .avatar-profile {
    height: 3em;
    width: 3em;
    border-radius: 10px;
    background-color: var(--profile-bg-color);
    border: solid 2px var(--rsrg-orange);
    color: var(--rsrg-orange);
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
  }

  @media only screen and (max-width: 800px) {
    .headline-container {
      height: 5.5em;
      width: 100%;
      padding: 0 1em;

      h1 {
        font-weight: 600;
        font-size: 1.2em;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    .headline-container {
      height: 5.5em;
      width: 100%;
      padding: 0 0.5em;

      h1 {
        font-weight: 600;
        font-size: 1em;
      }
    }
  }
</style>
