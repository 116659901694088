import {
  FetchParams,
  HTTPHeaders,
  Middleware,
  ResponseContext,
} from "@apis/backend";
import { Ref } from "vue";

export class ApiMiddleware implements Middleware {
  language: Ref<string>;

  constructor(language: Ref<string>) {
    this.language = language;
  }

  async pre(context: ResponseContext): Promise<FetchParams | void> {
    if (context.init.headers) {
      (context.init.headers as HTTPHeaders)["X-Language"] = this.language.value;
    } else {
      context.init.headers = { "X-Language": this.language.value };
    }
    return Promise.resolve({ url: context.url, init: context.init });
  }

  /*
  async post(context: ResponseContext): Promise<Response | void> {
    if (context.response.headers.has("X-RSRG-Error")) {
      const o = (await context.response.json()) as ApiFehler;
      return Promise.reject(new ApiError(o));
    } else {
      return Promise.resolve(context.response);
    }
  }
  */
}
