/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Betankung,
  LieferscheinExport,
  WerkstattAuftragAuswahl,
} from '../models/index';
import {
    BetankungFromJSON,
    BetankungToJSON,
    LieferscheinExportFromJSON,
    LieferscheinExportToJSON,
    WerkstattAuftragAuswahlFromJSON,
    WerkstattAuftragAuswahlToJSON,
} from '../models/index';

export interface BdsLieferscheinExportRequest {
    datum: string;
}

export interface BdsWerkstattauftraegeImportRequest {
    body?: string;
}

export interface WerkstattAuftraegeRequest {
    filter: string;
}

/**
 * BdsApi - interface
 * 
 * @export
 * @interface BdsApiInterface
 */
export interface BdsApiInterface {
    /**
     * 
     * @summary Gibt eine Schnittstellendatei für BDS-Lieferscheine aller Lümatic-Tankvorgänge eines Monats als Data-URI zurück.
     * @param {string} datum Monat des Exports
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BdsApiInterface
     */
    bdsLieferscheinExportRaw(requestParameters: BdsLieferscheinExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieferscheinExport>>;

    /**
     * Gibt eine Schnittstellendatei für BDS-Lieferscheine aller Lümatic-Tankvorgänge eines Monats als Data-URI zurück.
     */
    bdsLieferscheinExport(requestParameters: BdsLieferscheinExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieferscheinExport>;

    /**
     * 
     * @summary Ruft alle übergebenen Tankvorgänge ab, denen kein Werkstattauftrag zugeordnet wurde.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BdsApiInterface
     */
    bdsOffeneBetankungenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Betankung>>>;

    /**
     * Ruft alle übergebenen Tankvorgänge ab, denen kein Werkstattauftrag zugeordnet wurde.
     */
    bdsOffeneBetankungen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Betankung>>;

    /**
     * 
     * @summary Importiert Werkstattaufträge aus BDS
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BdsApiInterface
     */
    bdsWerkstattauftraegeImportRaw(requestParameters: BdsWerkstattauftraegeImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Importiert Werkstattaufträge aus BDS
     */
    bdsWerkstattauftraegeImport(requestParameters: BdsWerkstattauftraegeImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Gibt eine Liste von maximal 50 dem RSQL-Filter entsprechenden Werkstattaufträgen zurück.
     * @param {string} filter RSQL-Filter für Werkstattaufträge mit Eigenschaften aus WerkstattAuftragAuswahl (z.B. kennzeichen&#x3D;contains&#x3D;\&#39;98ir\&#39;)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BdsApiInterface
     */
    werkstattAuftraegeRaw(requestParameters: WerkstattAuftraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WerkstattAuftragAuswahl>>>;

    /**
     * Gibt eine Liste von maximal 50 dem RSQL-Filter entsprechenden Werkstattaufträgen zurück.
     */
    werkstattAuftraege(requestParameters: WerkstattAuftraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WerkstattAuftragAuswahl>>;

}

/**
 * 
 */
export class BdsApi extends runtime.BaseAPI implements BdsApiInterface {

    /**
     * Gibt eine Schnittstellendatei für BDS-Lieferscheine aller Lümatic-Tankvorgänge eines Monats als Data-URI zurück.
     */
    async bdsLieferscheinExportRaw(requestParameters: BdsLieferscheinExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LieferscheinExport>> {
        if (requestParameters.datum === null || requestParameters.datum === undefined) {
            throw new runtime.RequiredError('datum','Required parameter requestParameters.datum was null or undefined when calling bdsLieferscheinExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/bds/lieferscheineExport/{datum}`.replace(`{${"datum"}}`, encodeURIComponent(String(requestParameters.datum))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LieferscheinExportFromJSON(jsonValue));
    }

    /**
     * Gibt eine Schnittstellendatei für BDS-Lieferscheine aller Lümatic-Tankvorgänge eines Monats als Data-URI zurück.
     */
    async bdsLieferscheinExport(requestParameters: BdsLieferscheinExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LieferscheinExport> {
        const response = await this.bdsLieferscheinExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Ruft alle übergebenen Tankvorgänge ab, denen kein Werkstattauftrag zugeordnet wurde.
     */
    async bdsOffeneBetankungenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Betankung>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/bds/offeneTankungen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BetankungFromJSON));
    }

    /**
     * Ruft alle übergebenen Tankvorgänge ab, denen kein Werkstattauftrag zugeordnet wurde.
     */
    async bdsOffeneBetankungen(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Betankung>> {
        const response = await this.bdsOffeneBetankungenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Importiert Werkstattaufträge aus BDS
     */
    async bdsWerkstattauftraegeImportRaw(requestParameters: BdsWerkstattauftraegeImportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/bds/werkstattauftraege/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Importiert Werkstattaufträge aus BDS
     */
    async bdsWerkstattauftraegeImport(requestParameters: BdsWerkstattauftraegeImportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bdsWerkstattauftraegeImportRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt eine Liste von maximal 50 dem RSQL-Filter entsprechenden Werkstattaufträgen zurück.
     */
    async werkstattAuftraegeRaw(requestParameters: WerkstattAuftraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WerkstattAuftragAuswahl>>> {
        if (requestParameters.filter === null || requestParameters.filter === undefined) {
            throw new runtime.RequiredError('filter','Required parameter requestParameters.filter was null or undefined when calling werkstattAuftraege.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/bds/werkstattauftraege`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WerkstattAuftragAuswahlFromJSON));
    }

    /**
     * Gibt eine Liste von maximal 50 dem RSQL-Filter entsprechenden Werkstattaufträgen zurück.
     */
    async werkstattAuftraege(requestParameters: WerkstattAuftraegeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WerkstattAuftragAuswahl>> {
        const response = await this.werkstattAuftraegeRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
