/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LuematicImport
 */
export interface LuematicImport {
    /**
     * 
     * @type {string}
     * @memberof LuematicImport
     */
    importMonat: string;
    /**
     * 
     * @type {string}
     * @memberof LuematicImport
     */
    importiertAm: string;
    /**
     * 
     * @type {string}
     * @memberof LuematicImport
     */
    importiertVon: string;
    /**
     * 
     * @type {string}
     * @memberof LuematicImport
     */
    zuletztExportiertAm?: string;
    /**
     * 
     * @type {string}
     * @memberof LuematicImport
     */
    zuletztExportiertVon?: string;
}

/**
 * Check if a given object implements the LuematicImport interface.
 */
export function instanceOfLuematicImport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "importMonat" in value;
    isInstance = isInstance && "importiertAm" in value;
    isInstance = isInstance && "importiertVon" in value;

    return isInstance;
}

export function LuematicImportFromJSON(json: any): LuematicImport {
    return LuematicImportFromJSONTyped(json, false);
}

export function LuematicImportFromJSONTyped(json: any, ignoreDiscriminator: boolean): LuematicImport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importMonat': json['importMonat'],
        'importiertAm': json['importiertAm'],
        'importiertVon': json['importiertVon'],
        'zuletztExportiertAm': !exists(json, 'zuletztExportiertAm') ? undefined : json['zuletztExportiertAm'],
        'zuletztExportiertVon': !exists(json, 'zuletztExportiertVon') ? undefined : json['zuletztExportiertVon'],
    };
}

export function LuematicImportToJSON(value?: LuematicImport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importMonat': value.importMonat,
        'importiertAm': value.importiertAm,
        'importiertVon': value.importiertVon,
        'zuletztExportiertAm': value.zuletztExportiertAm,
        'zuletztExportiertVon': value.zuletztExportiertVon,
    };
}

