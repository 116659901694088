<template>
  <Button type="button" label="Anmelden" @click="loginRedirect" />
</template>

<script setup lang="ts">
  import { useMsal } from "@/composition-api/useMsal";
  import { scopesGraph } from "@/authConfig";
  import { RedirectRequest } from "@azure/msal-browser";
  import Button from "primevue/button";

  const { instance } = useMsal();

  const loginRequest: RedirectRequest = {
    scopes: scopesGraph,
  };

  const loginRedirect = () => {
    instance.loginRedirect(loginRequest);
  };
</script>
