import { computed, getCurrentInstance } from "vue";

export const locale = computed(() => {
    if (getCurrentInstance()!.appContext.config.globalProperties.$language.value == 'de') {
        return 'de-DE';
    } else {
        return 'en-US'
    }
}
);
