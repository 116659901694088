/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Betankung } from './Betankung';
import {
    BetankungFromJSON,
    BetankungFromJSONTyped,
    BetankungToJSON,
} from './Betankung';

/**
 * 
 * @export
 * @interface CsvSubmitRequest
 */
export interface CsvSubmitRequest {
    /**
     * Liste von Sammelkarten-Tankvorgängen, die alle einen zugeordneten Werkstattauftrag enthalten.
     * @type {Array<Betankung>}
     * @memberof CsvSubmitRequest
     */
    sammelkartenBetankungen: Array<Betankung>;
    /**
     * Signatur vom Ergebnis der Prüfung.
     * @type {string}
     * @memberof CsvSubmitRequest
     */
    signatur: string;
}

/**
 * Check if a given object implements the CsvSubmitRequest interface.
 */
export function instanceOfCsvSubmitRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sammelkartenBetankungen" in value;
    isInstance = isInstance && "signatur" in value;

    return isInstance;
}

export function CsvSubmitRequestFromJSON(json: any): CsvSubmitRequest {
    return CsvSubmitRequestFromJSONTyped(json, false);
}

export function CsvSubmitRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CsvSubmitRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sammelkartenBetankungen': ((json['sammelkartenBetankungen'] as Array<any>).map(BetankungFromJSON)),
        'signatur': json['signatur'],
    };
}

export function CsvSubmitRequestToJSON(value?: CsvSubmitRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'sammelkartenBetankungen': ((value.sammelkartenBetankungen as Array<any>).map(BetankungToJSON)),
        'signatur': value.signatur,
    };
}

