/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VorerfassteBetankung
 */
export interface VorerfassteBetankung {
    /**
     * 
     * @type {number}
     * @memberof VorerfassteBetankung
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof VorerfassteBetankung
     */
    fahrzeugNummer: number;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    werkstattauftragNummer: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    zeitpunkt: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    notiz?: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    erstelltAm?: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    erstelltVon?: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    editiertAm?: string;
    /**
     * 
     * @type {string}
     * @memberof VorerfassteBetankung
     */
    editiertVon?: string;
}

/**
 * Check if a given object implements the VorerfassteBetankung interface.
 */
export function instanceOfVorerfassteBetankung(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "fahrzeugNummer" in value;
    isInstance = isInstance && "werkstattauftragNummer" in value;
    isInstance = isInstance && "zeitpunkt" in value;

    return isInstance;
}

export function VorerfassteBetankungFromJSON(json: any): VorerfassteBetankung {
    return VorerfassteBetankungFromJSONTyped(json, false);
}

export function VorerfassteBetankungFromJSONTyped(json: any, ignoreDiscriminator: boolean): VorerfassteBetankung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fahrzeugNummer': json['fahrzeugNummer'],
        'werkstattauftragNummer': json['werkstattauftragNummer'],
        'zeitpunkt': json['zeitpunkt'],
        'notiz': !exists(json, 'notiz') ? undefined : json['notiz'],
        'erstelltAm': !exists(json, 'erstelltAm') ? undefined : json['erstelltAm'],
        'erstelltVon': !exists(json, 'erstelltVon') ? undefined : json['erstelltVon'],
        'editiertAm': !exists(json, 'editiertAm') ? undefined : json['editiertAm'],
        'editiertVon': !exists(json, 'editiertVon') ? undefined : json['editiertVon'],
    };
}

export function VorerfassteBetankungToJSON(value?: VorerfassteBetankung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fahrzeugNummer': value.fahrzeugNummer,
        'werkstattauftragNummer': value.werkstattauftragNummer,
        'zeitpunkt': value.zeitpunkt,
        'notiz': value.notiz,
        'erstelltAm': value.erstelltAm,
        'erstelltVon': value.erstelltVon,
        'editiertAm': value.editiertAm,
        'editiertVon': value.editiertVon,
    };
}

