import { computed, getCurrentInstance, reactive, Ref, watch } from "vue";
import { defineStore } from "pinia";
import UserInfo from "../utils/UserInfo";
import { useMsal } from "./useMsal";
import { useGraphApi } from "./useMsGraph";
import { blobToDataUri } from "@rsrg-bbw/libraries-common/dist/blobUtils";

export function useUserMagic() {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    throw new Error("Must only be used inside setup() function of a component");
  }

  const apis = useGraphApi();
  const store = useUserMagicStore();

  async function ensureToken(): Promise<void> {
    return new Promise((accept, reject) => {
      if (apis.isTokenInitialized.value) {
        accept();
      } else {
        const cancelWatch = watch(apis.isTokenInitialized, (v) => {
          if (v) {
            cancelWatch();
            accept();
          }
        });
      }
    });
  }

  async function fetchUserInfo(id: string): Promise<UserInfo> {
    await ensureToken();
    if (id !== currentUserId.value) {
      return apis.users.getUserInfo({ id: id });
    } else {
      return apis.me.getCurrentUserInfo();
    }
  }

  async function getUserInfo(id: string): Promise<UserInfo> {
    if (store.userInfoMap[id] === undefined) {
      const userInfo = fetchUserInfo(id);
      store.userInfoMap[id] = userInfo;
      return userInfo;
    } else {
      return store.userInfoMap[id];
    }
  }

  async function fetchUserPhoto(id: string): Promise<string> {
    await ensureToken();
    if (id !== currentUserId.value) {
      return await blobToDataUri(await apis.users.getUserPhoto({ id: id }));
    } else {
      return await blobToDataUri(await apis.me.getCurrentUserPhoto());
    }
  }

  async function getUserPhoto(id: string): Promise<string | undefined> {
    if (store.userPhotoMap[id] === undefined) {
      const p = fetchUserPhoto(id).catch((e) => {
        if (e?.response?.status === 404) {
          return new Promise<string | undefined>(() => undefined);
        } else {
          throw e;
        }
      });
      store.userPhotoMap[id] = p;
      return p;
    } else {
      return store.userPhotoMap[id];
    }
  }

  const { accounts } = useMsal();
  const currentUserId: Ref<string | undefined> = computed(() => {
    if (accounts.value.length > 0) {
      return accounts.value[0].localAccountId;
    } else {
      return undefined;
    }
  });

  return {
    currentUserId,
    getUserInfo,
    getUserPhoto,
  };
}

type UserInfoMap = {
  [key: string]: Promise<UserInfo>;
};
type UserPhotoMap = {
  [key: string]: Promise<string | undefined>;
};

const useUserMagicStore = defineStore("userMagic", () => {
  const userInfoMap = reactive<UserInfoMap>({});
  const userPhotoMap = reactive<UserPhotoMap>({});

  return {
    userInfoMap,
    userPhotoMap,
  };
});
