<template>
  <div class="failed">
    <h2>Anmeldung am System fehlgeschlagen.</h2>
  </div>
</template>

<style>
  .failed {
    margin-top: 5em;
  }
</style>
