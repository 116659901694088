/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CsvCheckRequest,
  CsvCheckResponse,
  CsvSubmitRequest,
  LuematicImport,
  VorerfassteBetankung,
  VorerfassteBetankungenResponse,
} from '../models/index';
import {
    CsvCheckRequestFromJSON,
    CsvCheckRequestToJSON,
    CsvCheckResponseFromJSON,
    CsvCheckResponseToJSON,
    CsvSubmitRequestFromJSON,
    CsvSubmitRequestToJSON,
    LuematicImportFromJSON,
    LuematicImportToJSON,
    VorerfassteBetankungFromJSON,
    VorerfassteBetankungToJSON,
    VorerfassteBetankungenResponseFromJSON,
    VorerfassteBetankungenResponseToJSON,
} from '../models/index';

export interface BetankungVorerfassenRequest {
    vorerfassteBetankung?: VorerfassteBetankung;
}

export interface LuematicCsvCheckRequest {
    csvCheckRequest?: CsvCheckRequest;
}

export interface LuematicCsvEinreichenRequest {
    csvSubmitRequest?: CsvSubmitRequest;
}

export interface LuematicImportHerunterladenRequest {
    monat: string;
}

export interface LuematicImportsRequest {
    jahr: number;
}

export interface VorerfassteBetankungLoeschenRequest {
    id: number;
}

export interface VorerfassteBetankungenRequest {
    filter?: string;
}

/**
 * LuematicApi - interface
 * 
 * @export
 * @interface LuematicApiInterface
 */
export interface LuematicApiInterface {
    /**
     * 
     * @summary Erfasst einen Tankvorgang über eine Sammelkarte, um diesen beim Monatsimport zuordnen zu können.
     * @param {VorerfassteBetankung} [vorerfassteBetankung] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    betankungVorerfassenRaw(requestParameters: BetankungVorerfassenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VorerfassteBetankung>>;

    /**
     * Erfasst einen Tankvorgang über eine Sammelkarte, um diesen beim Monatsimport zuordnen zu können.
     */
    betankungVorerfassen(requestParameters: BetankungVorerfassenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VorerfassteBetankung>;

    /**
     * 
     * @summary Prüft die Lümatic CSV-Exportdatei
     * @param {CsvCheckRequest} [csvCheckRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    luematicCsvCheckRaw(requestParameters: LuematicCsvCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CsvCheckResponse>>;

    /**
     * Prüft die Lümatic CSV-Exportdatei
     */
    luematicCsvCheck(requestParameters: LuematicCsvCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CsvCheckResponse>;

    /**
     * 
     * @summary Reicht eine CSV-Datei ein, wo alle Sammelkarten-Tankvorgänge einem Werkstattauftrag zugeordnet wurden.
     * @param {CsvSubmitRequest} [csvSubmitRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    luematicCsvEinreichenRaw(requestParameters: LuematicCsvEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Reicht eine CSV-Datei ein, wo alle Sammelkarten-Tankvorgänge einem Werkstattauftrag zugeordnet wurden.
     */
    luematicCsvEinreichen(requestParameters: LuematicCsvEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Gibt die originale CSV-Import-Datei zurück.
     * @param {string} monat Monat der Importdatei
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    luematicImportHerunterladenRaw(requestParameters: LuematicImportHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>>;

    /**
     * Gibt die originale CSV-Import-Datei zurück.
     */
    luematicImportHerunterladen(requestParameters: LuematicImportHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string>;

    /**
     * 
     * @summary Gibt eine Liste aller Importe eines Jahres zurück.
     * @param {number} jahr Jahr, für das Importe abgerufen werden sollen.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    luematicImportsRaw(requestParameters: LuematicImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LuematicImport>>>;

    /**
     * Gibt eine Liste aller Importe eines Jahres zurück.
     */
    luematicImports(requestParameters: LuematicImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LuematicImport>>;

    /**
     * 
     * @summary Gibt eine Liste mit Sammeltankkarten zurück.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    sammeltankKartenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>>;

    /**
     * Gibt eine Liste mit Sammeltankkarten zurück.
     */
    sammeltankKarten(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>>;

    /**
     * 
     * @summary Löscht einen vorerfassten Tankvorgang.
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    vorerfassteBetankungLoeschenRaw(requestParameters: VorerfassteBetankungLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Löscht einen vorerfassten Tankvorgang.
     */
    vorerfassteBetankungLoeschen(requestParameters: VorerfassteBetankungLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Gibt eine Liste von vorerfassten Tankvorgängen zurück.
     * @param {string} [filter] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LuematicApiInterface
     */
    vorerfassteBetankungenRaw(requestParameters: VorerfassteBetankungenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VorerfassteBetankungenResponse>>;

    /**
     * Gibt eine Liste von vorerfassten Tankvorgängen zurück.
     */
    vorerfassteBetankungen(requestParameters: VorerfassteBetankungenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VorerfassteBetankungenResponse>;

}

/**
 * 
 */
export class LuematicApi extends runtime.BaseAPI implements LuematicApiInterface {

    /**
     * Erfasst einen Tankvorgang über eine Sammelkarte, um diesen beim Monatsimport zuordnen zu können.
     */
    async betankungVorerfassenRaw(requestParameters: BetankungVorerfassenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VorerfassteBetankung>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/vorerfassung`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: VorerfassteBetankungToJSON(requestParameters.vorerfassteBetankung),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VorerfassteBetankungFromJSON(jsonValue));
    }

    /**
     * Erfasst einen Tankvorgang über eine Sammelkarte, um diesen beim Monatsimport zuordnen zu können.
     */
    async betankungVorerfassen(requestParameters: BetankungVorerfassenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VorerfassteBetankung> {
        const response = await this.betankungVorerfassenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Prüft die Lümatic CSV-Exportdatei
     */
    async luematicCsvCheckRaw(requestParameters: LuematicCsvCheckRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CsvCheckResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/importCsvCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CsvCheckRequestToJSON(requestParameters.csvCheckRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CsvCheckResponseFromJSON(jsonValue));
    }

    /**
     * Prüft die Lümatic CSV-Exportdatei
     */
    async luematicCsvCheck(requestParameters: LuematicCsvCheckRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CsvCheckResponse> {
        const response = await this.luematicCsvCheckRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reicht eine CSV-Datei ein, wo alle Sammelkarten-Tankvorgänge einem Werkstattauftrag zugeordnet wurden.
     */
    async luematicCsvEinreichenRaw(requestParameters: LuematicCsvEinreichenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/importCsvEinreichen`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CsvSubmitRequestToJSON(requestParameters.csvSubmitRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Reicht eine CSV-Datei ein, wo alle Sammelkarten-Tankvorgänge einem Werkstattauftrag zugeordnet wurden.
     */
    async luematicCsvEinreichen(requestParameters: LuematicCsvEinreichenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.luematicCsvEinreichenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt die originale CSV-Import-Datei zurück.
     */
    async luematicImportHerunterladenRaw(requestParameters: LuematicImportHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.monat === null || requestParameters.monat === undefined) {
            throw new runtime.RequiredError('monat','Required parameter requestParameters.monat was null or undefined when calling luematicImportHerunterladen.');
        }

        const queryParameters: any = {};

        if (requestParameters.monat !== undefined) {
            queryParameters['monat'] = requestParameters.monat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/importCsvHerunterladen`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Gibt die originale CSV-Import-Datei zurück.
     */
    async luematicImportHerunterladen(requestParameters: LuematicImportHerunterladenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.luematicImportHerunterladenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste aller Importe eines Jahres zurück.
     */
    async luematicImportsRaw(requestParameters: LuematicImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<LuematicImport>>> {
        if (requestParameters.jahr === null || requestParameters.jahr === undefined) {
            throw new runtime.RequiredError('jahr','Required parameter requestParameters.jahr was null or undefined when calling luematicImports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/imports/{jahr}`.replace(`{${"jahr"}}`, encodeURIComponent(String(requestParameters.jahr))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LuematicImportFromJSON));
    }

    /**
     * Gibt eine Liste aller Importe eines Jahres zurück.
     */
    async luematicImports(requestParameters: LuematicImportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<LuematicImport>> {
        const response = await this.luematicImportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gibt eine Liste mit Sammeltankkarten zurück.
     */
    async sammeltankKartenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<number>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/sammeltankkarten`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Gibt eine Liste mit Sammeltankkarten zurück.
     */
    async sammeltankKarten(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<number>> {
        const response = await this.sammeltankKartenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Löscht einen vorerfassten Tankvorgang.
     */
    async vorerfassteBetankungLoeschenRaw(requestParameters: VorerfassteBetankungLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling vorerfassteBetankungLoeschen.');
        }

        const queryParameters: any = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/vorerfassung`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Löscht einen vorerfassten Tankvorgang.
     */
    async vorerfassteBetankungLoeschen(requestParameters: VorerfassteBetankungLoeschenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.vorerfassteBetankungLoeschenRaw(requestParameters, initOverrides);
    }

    /**
     * Gibt eine Liste von vorerfassten Tankvorgängen zurück.
     */
    async vorerfassteBetankungenRaw(requestParameters: VorerfassteBetankungenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<VorerfassteBetankungenResponse>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("oauth2_auth", []);
        }

        const response = await this.request({
            path: `/api/v1/luematic/vorerfassung`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VorerfassteBetankungenResponseFromJSON(jsonValue));
    }

    /**
     * Gibt eine Liste von vorerfassten Tankvorgängen zurück.
     */
    async vorerfassteBetankungen(requestParameters: VorerfassteBetankungenRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<VorerfassteBetankungenResponse> {
        const response = await this.vorerfassteBetankungenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
