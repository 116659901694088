import {
  AccountInfo,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import {
  ComponentInternalInstance,
  getCurrentInstance,
  Ref,
  toRefs,
} from "vue";

export type MsalContext = {
  instance: PublicClientApplication;
  accounts: Ref<AccountInfo[]>;
  currentInteractionStatus: Ref<InteractionStatus>;
};

export function useMsal(
  currentInstance: ComponentInternalInstance | null = getCurrentInstance(),
): MsalContext {
  const internalInstance = currentInstance;
  if (!internalInstance) {
    throw "useMsal() cannot be called outside the setup() function of a component";
  }
  const { instance, accounts, inProgress } = toRefs(
    internalInstance.appContext.config.globalProperties.$msal,
  );

  if (!instance.value || !accounts.value || !inProgress.value) {
    throw "Please install the msalPlugin";
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.handleRedirectPromise().catch(() => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
      console.error("useMsal: login failed");
      return;
    });
  }

  return {
    instance: instance.value,
    accounts,
    currentInteractionStatus: inProgress,
  };
}
