/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LieferscheinExport
 */
export interface LieferscheinExport {
    /**
     * 
     * @type {string}
     * @memberof LieferscheinExport
     */
    exportFileUri: string;
    /**
     * 
     * @type {number}
     * @memberof LieferscheinExport
     */
    gesamtMenge: number;
    /**
     * 
     * @type {string}
     * @memberof LieferscheinExport
     */
    einheit: string;
    /**
     * 
     * @type {number}
     * @memberof LieferscheinExport
     */
    anzahlLieferscheine: number;
    /**
     * 
     * @type {number}
     * @memberof LieferscheinExport
     */
    anzahlBetankungen: number;
}

/**
 * Check if a given object implements the LieferscheinExport interface.
 */
export function instanceOfLieferscheinExport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "exportFileUri" in value;
    isInstance = isInstance && "gesamtMenge" in value;
    isInstance = isInstance && "einheit" in value;
    isInstance = isInstance && "anzahlLieferscheine" in value;
    isInstance = isInstance && "anzahlBetankungen" in value;

    return isInstance;
}

export function LieferscheinExportFromJSON(json: any): LieferscheinExport {
    return LieferscheinExportFromJSONTyped(json, false);
}

export function LieferscheinExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): LieferscheinExport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exportFileUri': json['exportFileUri'],
        'gesamtMenge': json['gesamtMenge'],
        'einheit': json['einheit'],
        'anzahlLieferscheine': json['anzahlLieferscheine'],
        'anzahlBetankungen': json['anzahlBetankungen'],
    };
}

export function LieferscheinExportToJSON(value?: LieferscheinExport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exportFileUri': value.exportFileUri,
        'gesamtMenge': value.gesamtMenge,
        'einheit': value.einheit,
        'anzahlLieferscheine': value.anzahlLieferscheine,
        'anzahlBetankungen': value.anzahlBetankungen,
    };
}

