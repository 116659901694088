import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import FailedView from "../views/FailedView.vue";
import { RouteNames } from './RouteNames';
import { registerGuard } from "./Guard";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
 
  },
  {
    path: "/failed",
    name: "Failed",
    component: FailedView,
  },
  {
    path: "/upload",
    name: RouteNames.UPLOAD_LUEMATIC,
    component: () => import("../views/UploadLuematicView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Lümatic Export-Datei",
    },
  },
  {
    path: "/download/bds",
    name: RouteNames.DOWNLOAD_BDS,
    component: () => import("../views/DownloadBdsView.vue"),
    meta: {
      requiresAuth: true,
      headline: "BDS Lieferscheine",
    },
  },
  {
    path: "/download/luematic",
    name: RouteNames.DOWNLOAD_LUEMATIC,
    component: () => import("../views/DownloadLuematicView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Lümatic Dateien",
    },
  },
  {
    path: "/vorerfassen",
    name: RouteNames.NOTES,
    component: () => import("../views/NotesView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Sammelkarten Tankvorgänge",
    },
  },
  {
    path: "/werkstattauftraege",
    name: RouteNames.ORDERS,
    component: () => import("../views/WorkshopOrdersView.vue"),
    meta: {
      requiresAuth: true,
      headline: "Werkstattaufträge",
    },
  },
  {
    // Small workaround for MSAL: The redirect back looks like "#/code=0.AR8..."
    // VueRouter does not like that so to prevent useless console errors, we just make it a valid route to home.
    path: "/code=:afterCode(.*)",
    name: "OAuthCodeReturn",
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

registerGuard(router);

export default router;
