import { computed } from "vue";
import { useOauthPermissionsInfo } from "./useOauthPermissionsInfo";
import { oauthRoles } from "@/authConfig";

export function useOauthRoles() {
  const oauthPermissions = useOauthPermissionsInfo();

  const canUserEditLuematic = computed(() => oauthPermissions.hasAnyRole(oauthRoles.LUEMATIC));
  const canUserDownloadLuematic = computed(() => oauthPermissions.hasAnyRole(oauthRoles.LUEMATIC_DOWNLOAD))

  const canUserDownloadBds = computed(() => oauthPermissions.hasAnyRole(oauthRoles.BDS));

  return {
    canUserEditLuematic,
    canUserDownloadLuematic,
    canUserDownloadBds,
  };

}
