import { ComponentInternalInstance } from "vue";
import { ImprovedMap } from "@rsrg-bbw/libraries-common/dist/ImprovedMap";

export class Lock {
  private name: string;
  private lockHeld: boolean;

  constructor(name: string) {
    this.name = name;
    this.lockHeld = false;
  }

  public async doExclusively<T>(f: () => T | Promise<T>): Promise<void> {
    if (!this.lockHeld) {
      this.lockHeld = true;
      try {
        console.log("GlobalLock: " + this.name + ": Acquired lock");
        await f();
      } finally {
        this.lockHeld = false;
        console.log("GlobalLock: " + this.name + ": Released lock");
      }
    } else {
      console.log("GlobalLock: " + this.name + ": Lock in use");
    }
  }
}

export function useGlobalLock(
  internalInstance: ComponentInternalInstance,
  name: string,
): Lock {
  let locks = internalInstance.appContext.config.globalProperties.$globalLocks;
  if (!locks) {
    locks = internalInstance.appContext.config.globalProperties.$globalLocks =
      new ImprovedMap();
  }
  return locks.computeIfAbsent(name, (k) => new Lock(k))!;
}

declare module "vue" {
  interface ComponentCustomProperties {
    $globalLocks: ImprovedMap<string, Lock>;
  }
}
