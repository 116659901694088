/* tslint:disable */
/* eslint-disable */
/**
 * Luematic BDS backend
 * Stellt Endpunkte zum Verarbeiten von Lümatic Tankungen bereit, die über eine Lieferschein-Schnittstellen-Datei in BDS importiert werden können.
 *
 * The version of the OpenAPI document: 0.2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CsvCheckRequest
 */
export interface CsvCheckRequest {
    /**
     * 
     * @type {string}
     * @memberof CsvCheckRequest
     */
    csvDataUri: string;
}

/**
 * Check if a given object implements the CsvCheckRequest interface.
 */
export function instanceOfCsvCheckRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "csvDataUri" in value;

    return isInstance;
}

export function CsvCheckRequestFromJSON(json: any): CsvCheckRequest {
    return CsvCheckRequestFromJSONTyped(json, false);
}

export function CsvCheckRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CsvCheckRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'csvDataUri': json['csvDataUri'],
    };
}

export function CsvCheckRequestToJSON(value?: CsvCheckRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'csvDataUri': value.csvDataUri,
    };
}

