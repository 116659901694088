import { ComponentInternalInstance, onUnmounted, Ref, watch } from "vue";
import {
  AccountInfo,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from "@azure/msal-browser";
import { useMsal } from "./useMsal";
import { homeAccountId } from "@/utils/devutils";
import {
  AccessToken,
  getAccessTokenEffectiveRemainingDuration,
  getAccessTokenRemainingDuration,
  isNearExpiry,
} from "@/plugins/apiHelpers";
import { useGlobalLock } from "./useGlobalLock";
import doWhenOnline from "@rsrg-bbw/libraries-common/dist/doWhenOnline";
import { hasAllScopes } from "@/utils/oauth/oauthHelper";

export interface ApiContext {
  internalInstance: ComponentInternalInstance;
  oauthScopes: string[];
  accessToken: Ref<AccessToken | undefined>;
  accessTokenLock: Ref<boolean>;
  lockName: string;
  tokenValidSetter: (v: boolean) => void;
}

function needsMoreOauthScopes(
  accessToken: Ref<AccessToken | undefined>,
  oauthScopes: string[],
) {
  if (!accessToken.value) {
    throw new Error("Not sure if that is possible");
  } else {
    return !hasAllScopes(accessToken.value.scopes, ...oauthScopes);
  }
}

function shouldRenew(context: ApiContext) {
  return (
    !context.accessToken.value ||
    isNearExpiry(context.accessToken.value) ||
    needsMoreOauthScopes(context.accessToken, context.oauthScopes)
  );
}

async function updateAccessTokensMaybe(
  context: ApiContext,
  instance: PublicClientApplication,
  currentInteractionStatus: Ref<InteractionStatus>,
  accounts: Ref<AccountInfo[]>,
) {
  log(context, "checking lock");

  // This lock prevents two (or more) mounted components to fetch access keys at the same time.
  const lock = useGlobalLock(context.internalInstance, context.lockName);
  if (shouldRenew(context)) {
    instance.getAllAccounts();
    await lock.doExclusively(async () => {
      log(context, "Try acquire token");
      try {
        const at = await instance.acquireTokenSilent({
          scopes: context.oauthScopes,
          forceRefresh: true,
        });
        //at.expiresOn = DateTime.now().plus({second: 40}).toJSDate();
        context.accessToken.value = at;
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          await instance.acquireTokenRedirect({
            scopes: context.oauthScopes,
          });
        }
        throw e;
      }

      homeAccountId.value =
        accounts.value.length > 0 ? accounts.value[0].homeAccountId : undefined;

      //(internalInstance.appContext.config.globalProperties.$msGraphApis).validToken.value = true;
      context.tokenValidSetter(true);
    });
  } else {
    log(context, "already valid");
  }
}

function registerRefresh(
  context: ApiContext,
  instance: PublicClientApplication,
  currentInteractionStatus: Ref<InteractionStatus>,
  accounts: Ref<AccountInfo[]>,
) {
  const internalInstance = context.internalInstance;
  log(context, "registering refresh");
  const doUpdate = async () =>
    updateAccessTokensMaybe(
      context,
      instance,
      currentInteractionStatus,
      accounts,
    );

  let timerRef: number | undefined;
  let onlineWaitCancel: () => void | undefined;
  let unwatch: () => void | undefined;
  const registerRefreshInternal = () => {
    // Ensure that after we get the first access token, we fetch a new one before it expires.
    // So wait until we even have a token...
    unwatch = watch(
      context.accessToken,
      (at) => {
        log(context, "AT changed");
        if (at) {
          log(context, "AT is not null, set timer for refresh");
          // ...when we do then set a timer based on the expiration...
          timerRef = window.setTimeout(() => {
            const actual = getAccessTokenRemainingDuration(at);
            const effective = getAccessTokenEffectiveRemainingDuration(at);
            log(
              context,
              `AT near expiration (actual: ${actual}, effective: ${effective}), refreshing...`,
            );

            // ...do it when we are online (now or later).
            const cancel = new AbortController();
            doWhenOnline(async () => {
              log(context, "online!");
              await doUpdate();
            }, cancel.signal);
            onlineWaitCancel = () => cancel.abort("not needed anymore");
          }, getAccessTokenEffectiveRemainingDuration(at).toMillis());
        }
      },
      { immediate: true },
    );
  };
  // I am not sure if we need to know if it is mounted. I wanted it clean and do it on mount since we do the counter suff on unmount.
  // Though, the component is mounted here already. So we just skip the onMount-stuff.
  // if(internalInstance.isMounted) {
  registerRefreshInternal();
  /* } else {
      onMounted(registerRefreshInternal, internalInstance);
    }*/

  onUnmounted(() => {
    if (timerRef) {
      console.log("ApiTokens: Removing timer");
      clearTimeout(timerRef);
    }
    if (onlineWaitCancel) {
      onlineWaitCancel();
    }
    unwatch?.();
  }, internalInstance);
}

export async function useBackendApiBase(context: ApiContext): Promise<void> {
  const { instance, currentInteractionStatus, accounts } = useMsal(
    context.internalInstance,
  );

  await updateAccessTokensMaybe(
    context,
    instance,
    currentInteractionStatus,
    accounts,
  );
  registerRefresh(context, instance, currentInteractionStatus, accounts);
}

function log(context: ApiContext, m: string) {
  console.log(
    "AccessTokens: " +
      context.lockName +
      ": " +
      context.internalInstance?.uid +
      ": " +
      m,
  );
}

declare module "vue" {}
