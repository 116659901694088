import { App, Ref, ref, watch } from "vue";
import { msalPlugin } from "./msalPlugin";
import {
  Configuration as BackendConfiguration,
  ConfigurationParameters,
  LuematicApi,
  BdsApi,
} from "@apis/backend";
import { AccessToken, isValidAccessToken } from "./apiHelpers";
import { ApiMiddleware } from "./ApiMiddleware";
import { BaseApis } from "@/plugins/baseApiPlugin";

export interface BackendApis extends BaseApis {
  luematic: LuematicApi;
  bds: BdsApi;
  callWhenTokenInitialized: (toCall: Function | Function[]) => void;
}

export const apiPlugin = {
  install: (app: App, msalPlg: typeof msalPlugin) => {
    // Do stuff that should happen once

    app.config.globalProperties.$backendApiAccessToken = ref<AccessToken>();
    app.config.globalProperties.$backendApiAccessTokenLock = ref(false);

    const accessTokenToHeader: (at?: AccessToken) => string = (at) => {
      if (isValidAccessToken(at)) {
        return "Bearer " + at!.accessToken;
      } else {
        throw new Error("Missing access token");
      }
    };

    let configBase: ConfigurationParameters = {
      middleware: [new ApiMiddleware(app.config.globalProperties.$language)],
    };
    const backendConfig = new BackendConfiguration({
      ...configBase,
      basePath: "/api/backend",
      accessToken: () =>
        accessTokenToHeader(
          app.config.globalProperties.$backendApiAccessToken.value,
        ),
    });

    const callWhenTokenInitialized = (toCall: Function | Function[]) => {
      let calls = Array.isArray(toCall) ? toCall : [toCall];
      if (app.config.globalProperties.$backendApis.isTokenInitialized.value) {
        calls.forEach((f) => f());
      } else {
        const unwatch = watch(
          app.config.globalProperties.$backendApis.isTokenInitialized,
          (v) => {
            if (v) {
              unwatch();
              calls.forEach((f) => f());
            }
          },
          { immediate: true },
        );
      }
    };

    app.config.globalProperties.$backendApis = {
      luematic: new LuematicApi(backendConfig),
      bds: new BdsApi(backendConfig),
      isTokenInitialized: ref(false),
      isTokenCurrentlyValid: ref(false),
      callWhenTokenInitialized: callWhenTokenInitialized,
    };
  },
};

declare module "vue" {
  interface ComponentCustomProperties {
    $backendApiAccessToken: Ref<AccessToken | undefined>;
    $backendApis: BackendApis;
    $backendApiAccessTokenLock: Ref<boolean>;
  }
}
